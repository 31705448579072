<template>
  <div class="associated">
    <div class="associated_top">
      <div class="associated_img">
        <img :src="companyinfo.companyLogo" alt="" />
        <span style="padding-left: 10px">{{ companyinfo.companyFullName }}</span>
      </div>
    </div>
    <div class="switch_tab">
      <div class="label_management">
        <el-table
          :data="listData"
          stripe
          style="width: 100%"
          class="in_table"
          @sort-change="changeSort"
          v-loading="loading"
          :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
        >
          <el-table-column label="编号" align="center" prop="id"></el-table-column>
          <el-table-column label="微信头像" align="center">
            <template v-slot="{ row }">
              <img :src="row.headImgUrl" width="42" height="42" alt="" />
            </template>
          </el-table-column>
          <el-table-column label="微信昵称" prop="" align="center">
            <template v-slot="{ row }">
              <span>{{ row.nickName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="姓名" prop="" align="center">
            <template v-slot="{ row }">
              <span>{{ row.realName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="性别" prop="" align="center">
            <template v-slot="{ row }">
              <span v-if="row.sex == 1">男</span>
              <span v-if="row.sex == 2">女</span>
            </template>
          </el-table-column>
          <el-table-column label="职位" prop="position" align="center"></el-table-column>

          <el-table-column label="手机号" prop="phone" align="center"></el-table-column>
          <el-table-column label="所在城市" prop="address" align="center"></el-table-column>

          <el-table-column label="名片" align="center">
            <template v-slot="{ row }">
            <div class="img_list" v-if="row.businessCard">
              <el-popover
                trigger="hover"
                width="350"
                v-for="(item, index) in row.businessCard.split(',')"
                :key="index"
                class="popover"
                placement="left"
              >
                <div slot="reference">
                  <img v-if="item" class="new_img" width="350" height="200" :src="item" alt="" />
                </div>
                <img v-if="item" class="new_img" width="350" height="200" :src="item" alt="" />
              </el-popover>
            </div>
          </template>
          </el-table-column>
          <el-table-column label="最近登录时间" width="180" prop="updateTime" align="center" sortable="">
            <template v-slot="{ row }">
              <div>{{ row.updateTime }}</div>
            </template>
          </el-table-column>
          <el-table-column label="创建时间"  width="180" prop="createTime" align="center" sortable="">
            <template v-slot="{ row }">
              <div>{{ row.createTime }}</div>
            </template>
          </el-table-column>
          <!-- <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
            <template>
              <el-button style="color: #146aff" type="text">冻结 </el-button>
            </template>
          </el-table-column> -->
        </el-table>
        <div class="new_page">
          <el-pagination
            :current-page="queryInfo.pageNum"
            :page-sizes="[10, 30, 50]"
            :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCorrelateUser, getCompanyDetail } from '@/api/datement'
import { getByFatherIdt, getProvince } from '@/api/demand'
export default {
  name: 'CorrelateUser',
  computed: {
    id() {
      return this.$route.query.id
    }
  },
  data() {
    return {
      queryInfo: {
        clientCompanyId: '',
        orders: '1',
        partnerChannelId: '1',
        pageNum: 1,
        pageSize: 10
      },
      parteninfo:JSON.parse(sessionStorage.getItem('typeMode')),
      listData: [],
      total: 0,
      companyinfo: {},
      loading: false,
      operatingLists: [],
      operatingList: []
    }
  },
  created() {
    this.getCorrelateUser()
    this.getcompany()
  },
  methods: {
    //查询企业信息回显
    async getcompany() {
      const res = await getCompanyDetail({ id: this.id })
      if (res.data.resultCode == 200) {
        this.companyinfo = res.data.data
      }
    },
    //用户一览
    async getCorrelateUser() {
      this.queryInfo.clientCompanyId = this.id
      this.queryInfo.partnerChannelId=this.parteninfo.id
      this.loading = true
      const res = await getCorrelateUser(this.queryInfo)
      if (res.data.resultCode == 200) {
        this.listData = res.data.data.list
        this.total = res.data.data.total
        this.loading = false
      }
    },
    //排序
    changeSort(val) {
      //val里面返回prop和order
      // 2->创建日期升序;3->创建日期降序;4->登陆次数升序;5->登陆次数降序;6->最近登陆时间升序;7->最近登陆时间降序
      console.log(val)

      if (val.prop == 'updateTime') {
        if (val.order == 'ascending') {
          //updateAt列升序时

          this.queryInfo.orders = '4'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.queryInfo.orders = '5'
          this.search()
        } else {
          //updateAt列不排序时
          this.queryInfo.orders = '1'
          this.search()
        }
      } else if (val.prop == 'createTime') {
        if (val.order == 'ascending') {
          //updateAt列升序时

          this.queryInfo.orders = '3'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.queryInfo.orders = '4'
          this.search()
        } else {
          //updateAt列不排序时
          this.queryInfo.orders = '1'
          this.search()
        }
      }
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .el_upload .el-form-item {
  margin-bottom: 0px;
}
::v-deep .name_form .el-input__suffix {
  position: absolute;
  height: 100%;
  right: -54px;
  top: 0;
  text-align: center;
  color: #c0c4cc;
  transition: all 0.3s;
  pointer-events: none;
}
::v-deep .el-step__title {
  font-size: 14px;
  line-height: 38px;
}

::v-deep .name_form .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 41px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 556px;
}
::v-deep .name_form .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 496px;
}

.associated {
  background: #ffffff;
  .img_list {
      .new_img {
        width: 70px;
        height: 40px;
        border-radius: 4px;
        margin-right: 10px;
      }
    }
  .img_commer {
    display: flex;
    border: 1px solid #146aff;
    border-radius: 39px;
    .name {
      font-size: 12px;
      line-height: 20px;
      color: #146aff;
    }
    .color_img {
      background: #146aff;
      height: 20px;
      width: 25px;
      border-radius: 20px;
      position: relative;
      left: -10px;
      img {
        width: 13px;
        height: 13px;
        position: relative;
        left: 5px;
        top: 2px;
      }
    }
  }
  .img_commers {
    display: flex;
    border: 1px solid #ff9c00;
    border-radius: 39px;
    margin: 0px 10px;
    .name {
      font-size: 12px;
      line-height: 20px;
      color: #ff9c00;
    }
    .color_img {
      background: #ff9c00;
      height: 20px;
      width: 25px;
      border-radius: 20px;
      position: relative;
      left: -10px;
      img {
        width: 13px;
        height: 13px;
        position: relative;
        left: 5px;
        top: 2px;
      }
    }
  }
  .associated_top {
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    .account_cycle {
      text-align: right;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;

      /* 灰色字体 */

      color: #969495;
    }
    .associated_img {
      display: flex;
      align-items: center;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;

      color: #151515;
      img {
        width: 67px;
        height: 54px;
        border-radius: 5px;
      }
    }
  }
  .switch_tab {
    background: #ffffff;
    margin-top: 20px;

    .essential_information {
      padding: 30px 40px;
      .general_input {
        width: 845px;
        border: 1px solid #d4d4d4;
        padding: 14px 16px;

        .input_flex {
          display: flex;
          justify-content: space-between;
          padding: 7px 0px;
          span {
            padding-left: 15px;
            font-size: 20px;
            cursor: pointer;
          }
        }
      }
      .img_tips {
        position: relative;
        top: -10px;
        color: #6ba1ff;
      }

      h4 {
        color: #146aff;
      }
      .dialog-footer {
        display: flex;
        justify-content: center;
      }
    }
  }

  .label_management {
    padding: 10px 10px;

    .new_page {
      margin: 20px auto;
      width: 100%;
      text-align: center;
    }

    .input-with {
      width: 100%;
      overflow: hidden;
      padding: 20px 0px;

      .input-with-select {
        width: 400px;
        float: left;
      }

      .seach {
        background-color: #448aff;
        color: #fff;
        border-radius: 0;
        border: 1px solid #448aff;
      }
    }
  }
}
::v-deep .input_width .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 415px;
}
</style>
